/* eslint-disable no-restricted-syntax */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { createCheck, triggerCheckBusinessRules } from "../../../../actions/Checks/actions";
import { addLayerLocationHistory, addLayerParent, getLayerChecks, patchLayer, removeLayerParent, updateLayerState } from "../../../../actions/Layers/actions";
import { Check, Layer, Related, isArtLocation } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { DeviceResultActions } from "../../../../actions/Tenants/config/constants";
import { CheckLocation, FormField } from "../../../../actions/Tenants/config/constantsTyped";
import { isFilledArray } from "../../../../utils";
import { findChanges } from "../../../../utils/findChanges";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";

export interface StartCheckFormProps {
    location_config: CheckLocation;
}

interface CreateCheckData {
    [key: string]: any;
}

export default function StartCheckForm({ location_config }: StartCheckFormProps) {
    const [createCheckData, setCheck] = useState<CreateCheckData>({});
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    // patch update
    const layerIsUpdating = useSelector<any>((state) => state.layers.isUpdating);
    const checkIsUpdating = useSelector<any>((state) => state.checks.isUpdating);
    const checks = useSelector<any, Check[]>((state) => state.layers.checks);
    const locationHistoryLoading = useSelector<any>((state) => state.layers.locationHistoryLoading);

    const config = useConfig();
    const dispatch = useDispatch();
    const params = useParams();

    const navigate = useNavigate();
    const [modalOpen, setModal] = useState(params.action === `start-${location_config.value}`);
    const shouldShowModal = isFilledArray(location_config?.meta_form_layer) || isFilledArray(location_config?.pre_check_form);

    useEffect(() => {
        if (params.action === `start-${location_config.value}`) {
            setModal(true);
        }
    }, [params.action]);


    const allocateToStage = () => {
        // explicit configuered stage
        let new_stage_value: string | undefined;
        if (location_config.allocate_stage) {
            new_stage_value = location_config.allocate_stage;
        } else {
            // Stage that has the same name
            const new_stage = config.stages.find((i) => i.value === location_config.value);
            if (new_stage) {
                new_stage_value = new_stage.value;
            }
        }
        // assign stage if current stage is different
        if (new_stage_value && new_stage_value !== layer.latest_location?.stage) {
            dispatch(addLayerLocationHistory({ layer_id: layer.id, stage: new_stage_value }) as any);
        }
    };

    const _updateLayerAndStartCheck = () => {

        if (layerIsUpdating || checkIsUpdating || locationHistoryLoading) {
            return;
        }

        if (location_config.meta_form_layer && !metaFormIsValid(location_config.meta_form_layer as FormField[], layer)) {
            return;
        }
        if (location_config.pre_check_form && !metaFormIsValid(location_config.pre_check_form as any, createCheckData)) {
            return;
        }


        allocateToStage();
        let location = location_config.value;

        // For mission...
        // if it is the first ripening check, and there is no first ripening check, go to first ripening check flow
        if (location_config.require_another_check) {
            //  check if one of the checks is first-ripening
            const hasFirstRipeningCheck = checks.find((i) => i.location === location_config.require_another_check);
            // to be backwards compatible
            const hasPreviousRipeningchecks = checks.filter((i) => i.location === location_config.value).length > 0;
            if (!hasFirstRipeningCheck && !hasPreviousRipeningchecks) {
                location = location_config.require_another_check;
            }
        }


        const test: Check = {
            layer_id: layer.id,
            location
        } as any;
        test.is_manual = location_config.is_manual || false;

        // * Set the requested_calibration_stage,
        // * needed to fetch and filter calibration fruit
        const { pressure_calibration_settings } = location_config;
        const requested_calibration_stage = pressure_calibration_settings?.calibration_stage || false;
        if (requested_calibration_stage) {
            test.requested_calibration_stage = requested_calibration_stage;
        }

        if (createCheckData?.is_manual) {
            createCheckData.is_manual = Boolean(createCheckData.is_manual);
        }
        const postCheck = { ...test, ...createCheckData };
        dispatch(createCheck(postCheck) as any).then((response) => {
            if (!response.error) {
                const location_config = config.get_location(layer, response.payload.data);

                // * This is a feature, not a bug. If no flow is defined we just close the modal.
                // * This way you can setup small checks to just ask small bits of info with pre_check_form
                if (location_config.flow.length === 0) {
                    dispatch(triggerCheckBusinessRules(response.payload.data.test_id) as any).then(() => {
                        dispatch(getLayerChecks(layer.id, { only_with_fruit: false, check_location: location_config.value }) as any);
                    });
                    setModal(false);
                    return;
                }
                navigate(`/layer/${layer.id}/add-check/${response.payload.data.test_id}/${location_config.flow[0]}`);
            }
        });
    };

    const isDisabled = () => {
        // We need to use calibration... but calibration is missing
        if (location_config.device_result_action === DeviceResultActions.pressure_advice && !config.is_location_calibrated(location_config, layer)) {
            return true;
        }
        // We need to setup calibration... but is is already calibrated
        if (location_config.device_result_action === DeviceResultActions.setup_pressure_calibration && config.is_location_calibrated(location_config, layer)) {
            return true;
        }
        if (layerIsUpdating || checkIsUpdating || locationHistoryLoading) {
            return true;
        }

        return false;
    };
    const startCheck = () => {
        // if it is advance-ripening and there is a previous advance_ripening check, go to that check
        if (isArtLocation(location_config.value)) {
            const prevCheck = checks.sort((a, b) => b.test_id - a.test_id).find((i) => i.location === location_config.value);
            if (prevCheck) {
                navigate(`/layer/${layer.id}/add-check/${prevCheck.test_id}/advance-ripening`);
                return;
            }
        }

        // only show modal if extra meta is required on this location
        if (shouldShowModal) {
            setModal(true);
            return;
        }
        _updateLayerAndStartCheck();
    };
    const submit = () => {
        _updateLayerAndStartCheck();
    };

    return (<div>
        <Button className="my-1 text-nowrap" color="primary" disabled={isDisabled()} onClick={startCheck}>{location_config.text} check</Button>
        <Modal isOpen={modalOpen} size={"md"} toggle={() => setModal(false)}>
            <ModalHeader toggle={() => setModal(false)} >{`${location_config.text + isArtLocation(location_config.value) ? "" : ` check`}`}</ModalHeader>
            <ModalBody>
                <div>
                    {location_config.meta_form_layer && <MetaForm
                        key="layer"
                        meta={location_config.meta_form_layer as any}
                        setValue={(field, value) => {
                            if (field === "parents") {
                                const { toBeAdded, toBeRemoved } = findChanges(layer.parents, value as Related[], (x, y) => x.id === y.id);
                                for (const parent of toBeAdded) {
                                    dispatch(addLayerParent(layer.id, parent.id) as any);
                                }
                                for (const parent of toBeRemoved) {
                                    dispatch(removeLayerParent(layer.id, parent.id) as any);
                                }
                            } else {
                                dispatch(updateLayerState({ ...layer, [field]: value }));
                            }
                        }}
                        onDebounce={(field, value) => {
                            if (field !== "parents") {
                                dispatch(patchLayer(layer.id, field, value) as any);
                            }
                        }}
                        object={layer}
                        config={config} />}
                    {location_config.pre_check_form && <MetaForm
                        key="check"
                        meta={location_config.pre_check_form}
                        setValue={(field, value) => setCheck((object) => ({ ...object, [field]: value }))}
                        object={createCheckData}
                        extra_context={{ fruit_variety: layer.fruit_variety || undefined, fruit_type: layer.fruit_type || undefined }}
                        config={config} />}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setModal(false)}>Cancel </Button>
                <Button disabled={isDisabled()} color="success" onClick={() => submit()}>{!location_config.submit_instead_of_start_check ? "Start" : "Submit"}</Button>
            </ModalFooter>
        </Modal >
    </div>);
}

StartCheckForm.propTypes = {
    location_config: PropTypes.object,
};
