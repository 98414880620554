/** @jsxImportSource @emotion/react */
import { faTemperatureHigh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import { MetaRow } from "./LayerMeta";
import { LAYER_TYPE } from "../../../../actions/Layers/constants";

export default function LayerParentsChildren() {
    const isLoading = useSelector((state) => state.layers.isLoading);
    const { children, parents } = useSelector((state) => state.layers.current);
    const layer = useSelector((state) => state.layers.current);

    const childrenWithMeta = useSelector((state) => state.layers.children);

    const layer_with_temp_sensors = childrenWithMeta?.results?.filter((i) => i.temptale_number && i?.temptale_number !== "None" && i?.temptale_number !== "-")
        .map((i) => i.label) || [];

    const config = useConfig();
    const displayLayerType = (type) => config.get_raw_layer_config(type)?.text || type;

    const layer_config = config.get_layer_config(layer);
    const { hide_sub_pallets } = layer_config;

    if (isLoading) {
        return <Loading />;
    }

    return <div className="pt-4">
        {parents && parents.length > 0 && <div>
            {parents.map((layer, index) => <MetaRow key={index} label={displayLayerType(layer.type)} value={<Link to={`/layer/${layer.id}`}>{layer.label}</Link>}></MetaRow>)}
        </div>}

        {children
            .filter(({ type }) => (!hide_sub_pallets || type !== LAYER_TYPE.SUBPALLET))
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(({ id, type, label }, index) => {
                const hasTempSensor = layer_with_temp_sensors.includes(label);
                const layerTypeDisplay = displayLayerType(type);

                return (
                    <MetaRow
                        key={index}
                        label={
                            <div>
                                {layerTypeDisplay}{" "}
                                {hasTempSensor && (
                                    <span className="me-2">
                                        <FontAwesomeIcon icon={faTemperatureHigh} />
                                    </span>
                                )}
                            </div>
                        }
                        value={<Link to={`/layer/${id}`}>{label}</Link>}
                    />
                );
            })}


    </div>;
}
