/* eslint-disable no-case-declarations */
import { combineReducers } from "redux";
import growerReducer, { GrowerState } from "./grower/reducer";
import internalEmailsReducer, { InternalEmailsState } from "./internalEmails/reducer";
import supplierReducer, { SupplierState } from "./supplier/reducer";
import userReducer, { UserState } from "./user/reducer";

export interface Entity {
    id: string;
}

export interface Filter {
    offset?: number;
    limit?: number;
}

export interface EntityState<T = Entity> {
    items: {
        results: T[];
        count: number;
    }
    isLoading: boolean;
    filter: Filter;
    localFiltering: boolean;
    reload: boolean;
}

export interface EmailRecipient { displayName: string, address: string }

export interface EmailRecipients {
    to: EmailRecipient[];
    cc: EmailRecipient[];
    bcc: EmailRecipient[];
}

export interface AdminState {
    supplier: SupplierState;
    grower: GrowerState;
    internalEmails: InternalEmailsState;
    user: UserState;
}

export const defaultFilter: Filter = { offset: 0, limit: 25 };


export default combineReducers({
    supplier: supplierReducer,
    grower: growerReducer,
    internalEmails: internalEmailsReducer,
    user: userReducer,
});
