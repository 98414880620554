/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { Filter } from "../../../actions/Admin/reducer";
import { getUsers, saveUser, updateUserFilter } from "../../../actions/Admin/user/actions";
import { User, UserState } from "../../../actions/Admin/user/reducer";
import useConfig from "../../../actions/Tenants/config/configHook";
import { FormField, UserRole } from "../../../actions/Tenants/config/constantsTyped";
import { RootState } from "../../../reducers";
import { getFilledArrayOrDefault } from "../../../utils";
import { Form, PageBase, PageBaseTableRowField } from "./PageBase";

interface UserFormObject extends Omit<User, "name" | "fruit_types"> {
    roles: string[];
    teams: string[];
}


const definedUserFormCreate = (onSaveUser: (user:User) => void) => () => {
    const userForm: FormField[] = [
        {
            label: "Roles",
            description: "Which roles this user has",
            type: "single-select",
            name: "roles",
            multi: true,
            return_array: true,
            options: [
                { value: UserRole.TENANT_ADMIN, label: "TenantAdmin" },
            ]
        },
        {
            label: "Teams",
            description: "To which teams this user belongs",
            type: "select-managed",
            name: "teams",
            multi: true,
            return_array: true,
        }
    ];
    const onSave = (formObject: UserFormObject, entity: Partial<User>) => {
        const user: any = {
            ...entity,
            ...formObject,
        };
        onSaveUser(user as User);
    };

    const getFormObjectFromEntity = (entity: Partial<User>): Partial<UserFormObject> => {
        return { ...entity };
    };
    const userModal: Form<UserFormObject, User> = {
        title: "User",
        fields: userForm,
        modalButtonLabel: "Edit",
        onSave,
        getFormObjectFromEntity
    };
    return userModal;
};


const UserPage = () => {
    const dispatch = useDispatch();
    const state = useSelector<RootState, UserState>((state) => state.admin.user);
    const config = useConfig();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const getOptionLabel = (optionType:string, value: any): string => {
        const options = allOptions?.[config.tenant_id]?.[optionType] || [];
        const option = options.find((option) => option.value === value);
        return option?.label || "";
    };
    const onSaveUser = async (entity: User) => {
        await dispatch(saveUser(entity));
    };
    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateUserFilter(filter));
    };
    const onListEntities = (filter: Filter) => {
        dispatch(getUsers(filter));
    };
    const tableRow: PageBaseTableRowField<User>[] = [
        {
            label: "UserName",
            name: "username",
            lg: 2,
        },
        {
            label: "Name",
            name: "first_name",
            lg: 3,
        },
        {
            label: "Email",
            name: "email",
            lg: 3,
        },
        {
            label: "Roles",
            name: "roles",
            lg: 2,
            display_component(entity:User) {
                return <div>{ getFilledArrayOrDefault(entity.roles).map((role, index) => <Badge key={index} className="me-2" color="secondary">{role}</Badge>)}</div>;
            },
        },
        {
            label: "Teams",
            name: "teams",
            lg: 2,
            display_component(entity:User) {
                return <div>{ getFilledArrayOrDefault(entity.teams).map((team, index) => <Badge key={index} className="me-2" color="secondary">{getOptionLabel("teams", team)}</Badge>)}</div>;
            },
        }

    ];
    const createUserForm = definedUserFormCreate(onSaveUser);
    const createForms = [createUserForm];

    return <PageBase<User>
        title="User"
        tableRow={tableRow}
        deletable={false}
        createable={false}
        createForms = {createForms as any}
        state={state}
        events={{ onUpdateFilter, onListEntities }}
    />;
};


export default UserPage;
