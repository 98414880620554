/** @jsxImportSource @emotion/react */
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { Alert, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as XLSX from "xlsx";
import { Layer } from "../../../actions/Layers/constants";
import { format2Frontend } from "../../../actions/Layers/util";
import useConfig, { useTenantFormOptions } from "../../../actions/Tenants/config/configHook";
import { RootConfigType } from "../../../actions/Tenants/config/constantsTyped";
import { convert_to_comma_seperated_list } from "../../../actions/util";
import { getFilledArrayOrDefault } from "../../../utils";
import { callAvosApi } from "../../../utils/useAvosApiHook";
import DatePicker from "../../Forms/DatePicker";
import { Loading } from "../../Helper/Loading";
import Field, { FormOptions } from "../Layers/fields/Field";
import { useSwitchTabContext } from "./fields/useSwitchTab";

interface DownloadFilter {
    created_from: Date | null;
    created_to: Date | null;
    select_fields: string | null;
}


const DownloadExcelModal = () => {
    const { fields, active_tab } = useSwitchTabContext();
    const [filter, setFilter] = useState<DownloadFilter>({ created_from: null, created_to: null, select_fields: null });
    const [isOpenDownloadModal, setDownloadModal] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const config: RootConfigType = useConfig();
    const form_options = useTenantFormOptions();
    const [errorMessage, setErrorMessage] = useState("");

    const toggleDownloadModal = () => setDownloadModal((prev) => !prev);

    const emptyRequest = useMemo(() => Object.keys(filter).every((key) => filter[key] === null), [filter]);

    const filteredFields = useMemo(() => {
        return getFilledArrayOrDefault(fields).filter((field) => !field.excluded_from_download);
    }, [fields]);

    const select_fields = useMemo(() => {
        return convert_to_comma_seperated_list(
            filteredFields.flatMap((field) => field.get_backend_fieldname())
        );
    }, [filteredFields]);

    const download = useCallback(async () => {
        try {
            setErrorMessage("");

            const { created_from, created_to } = filter;
            const differenceInDays = dayjs(created_to).diff(dayjs(created_from), "day");

            if (differenceInDays > 60) {
                setErrorMessage("Date range should not exceed 60 days");
                return;
            }

            setDownloading(true);

            const { data } = await callAvosApi(`/layers/layers_for_download`, { params: { ...filter, select_fields } });

            if (!data || data.length === 0) {
                setErrorMessage("Results not found");
            } else {
                const layers = data.map(format2Frontend);
                downloadExcelFile(layers, filteredFields, config, form_options, active_tab);

                resetFilter();
                setDownloadModal(false);
            }
        } catch (error) {
            setErrorMessage("An error occurred during the download process. Please try again later.");
        } finally {
            setDownloading(false);
        }
    }, [filter, select_fields, filteredFields, config, form_options]);

    const resetFilter = () => {
        setFilter({ created_from: null, created_to: null, select_fields: null });
    };

    return (
        <div className="pb-2 pb-md-0 pe-2">
            <Button color="light" onClick={toggleDownloadModal}>
                <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Modal size="md" isOpen={isOpenDownloadModal} toggle={toggleDownloadModal}>
                <ModalHeader toggle={toggleDownloadModal}>Download</ModalHeader>
                <ModalBody>
                    <div className="pb-2 mb-3">
                        <Label>Date</Label>
                        <div className="d-flex align-items-center">
                            <DatePicker
                                value={filter.created_from}
                                onChange={(value) => setFilter((i) => ({ ...i, created_from: value as Date }))}
                            />
                            <div className="px-2">to</div>
                            <DatePicker
                                value={filter.created_to}
                                onChange={(value) => setFilter((i) => ({ ...i, created_to: value as Date }))}
                            />
                        </div>
                    </div>
                    {errorMessage && !downloading && <Alert color="warning">{errorMessage}</Alert>}
                    {downloading && <Loading />}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        className="me-2"
                        disabled={downloading || emptyRequest || !filter.created_from || !filter.created_to}
                        onClick={download}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DownloadExcelModal;

const downloadExcelFile = (layers: Layer[], filteredFields: Field[], config: RootConfigType, form_options: FormOptions, active_tab: string | undefined) => {
    if (layers.length === 0) {
        return;
    }

    try {
        const worksheetData = layers.map((layer) => {
            const row: Record<string, string> = {};
            filteredFields.forEach((field) => {
                const valueObj = field.set_value({
                    config,
                    layer,
                    check: null,
                    fruit: null,
                    children: null,
                    form_options
                })?.formatted_value();

                // Store the value in the row, ensuring it's a string or empty string
                row[field.label] = valueObj != null ? valueObj.toString() : "";
            });
            return row;
        });

        const header = filteredFields.map((field) => field.label);

        // Create a new workbook and add a worksheet
        const workbook = XLSX.utils.book_new();

        // Create worksheet with field names as headers
        const worksheet = XLSX.utils.json_to_sheet(worksheetData, { header });

        // Append the worksheet to the workbook
        const datetime = dayjs().format("DD-MM-YYYY");
        XLSX.utils.book_append_sheet(workbook, worksheet, `export-${datetime}`);

        // Write the Excel file
        XLSX.writeFile(workbook, `export-${active_tab}-${datetime}.xlsx`);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error generating Excel file:", error);
    }
};

