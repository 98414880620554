/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import {
    Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { Layer } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { isFilledArray, matchesPattern } from "../../../../utils";
import LayerAutoComplete from "../../../Forms/LayerAutoComplete";
import QRCodeScanner from "../../../Forms/OldQRCodeScanner";
import { RadioButtons } from "../../../Forms/RadioButton";

interface CreateLayerModalProps {
    onClose: () => void;
    onSubmit: () => void;
    isOpen: { withScanner: boolean} | false;
    disableSaveButton: boolean;
    setValue: (field: string, value: any) => void;
    layer: Layer;
    setLayerSelect: (layer: Layer | Layer[]) => void;
  }

export function CreateLayerModal({
    onClose, onSubmit, isOpen, disableSaveButton, setValue, layer, setLayerSelect
}: CreateLayerModalProps) {
    const config = useConfig();

    const layer_config = config.get_layer_config(layer);
    const { label_format, invalid_hint } = layer_config;

    // When creating layers...check if the label matches the format
    const labelFormatInvalid = label_format && !matchesPattern(layer?.label, label_format);

    const handleScan = (result) => setValue("label", result);

    const creatableLayerTypes = config.layer_types && config.layer_types.filter((i) => i.is_creatable !== false);

    const openScanner = config.use_barcode_scanner && isOpen && isOpen.withScanner;

    return (
        <Modal isOpen={!!isOpen} size="md">
            <ModalHeader toggle={() => () => onClose()} onClick={() => onClose()}>Create {layer_config?.text}</ModalHeader>
            <ModalBody>

                { isFilledArray(creatableLayerTypes) && <div className="pb-2">
                    <FormGroup>
                        <Label >Type</Label>
                        <div>
                            <RadioButtons color="secondary" name="type" current={layer.type!} options={creatableLayerTypes} onChange={setValue}></RadioButtons>
                        </div>
                    </FormGroup>
                </div>
                }
                { openScanner && (
                    <div className="pb-2">
                        <QRCodeScanner onScan={handleScan} barcode_filter={config.barcode_filter} />
                    </div>
                )}
                <div className="pb-2">
                    <LayerAutoComplete
                        multi={false}
                        type={layer.type}
                        layer={layer}
                        setLayer={setLayerSelect}
                        label_format={label_format}
                        invalid_hint={invalid_hint} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => onClose()}>Cancel</Button>
                <Button disabled={disableSaveButton || labelFormatInvalid} color="success" onClick={() => onSubmit()}>Save</Button>
            </ModalFooter>
        </Modal>

    );
}


