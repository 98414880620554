/* eslint-disable no-unused-vars */
import {
    formatDate,
    toFixed,
    toPercentage
} from "../../components/scenes/Layers/fields/formatters";
import LayerStagesColorBadgeField from "../../components/scenes/Layers/fields/LayerStagesColorBadgeField";
import ListChildrenMetaField from "../../components/scenes/Layers/fields/ListChildrenMetaField";

import CheckColorBadgeField from "../../components/scenes/Layers/fields/CheckColorBadgeField";
import CheckField from "../../components/scenes/Layers/fields/CheckField";
import CheckFlagField from "../../components/scenes/Layers/fields/CheckFlagField";
import CheckLocationField from "../../components/scenes/Layers/fields/CheckLocationField";
import CountChildrenField from "../../components/scenes/Layers/fields/CountChildrenField";
import { DataSource } from "../../components/scenes/Layers/fields/Field";
import LabelLinkField from "../../components/scenes/Layers/fields/LabelLinkField";
import LayerField from "../../components/scenes/Layers/fields/LayerField";
import LayerFruitAgeField from "../../components/scenes/Layers/fields/LayerFruitAgeField";
import LayerFruitArrivalDelayField from "../../components/scenes/Layers/fields/LayerFruitArrivalDelayField";
import LayerLatestLocationField from "../../components/scenes/Layers/fields/LayerLatestLocationField";
import LayerMetaColorBadgeField from "../../components/scenes/Layers/fields/LayerMetaColorBadgeField";
import LayerMetaField from "../../components/scenes/Layers/fields/LayerMetaField";
import LayerStageColorBadgesField from "../../components/scenes/Layers/fields/LayerStageColorBadgesField";
import LayerStageField from "../../components/scenes/Layers/fields/LayerStageField";
import ListChildrenLabelLinkField from "../../components/scenes/Layers/fields/ListChildrenLabelLinkField";
import ParentLabelField from "../../components/scenes/Layers/fields/ParentLabelField";
import ParentLabelLinkField from "../../components/scenes/Layers/fields/ParentLabelLinkField";
import QCStatusField from "../../components/scenes/Layers/fields/QCStatusField";
import RipeningCellMetaFields from "../../components/scenes/RipeWise/fields/RipeningCellMetaFields";
import { FILTER_METHOD_TYPES } from "./actions";
import { LAYER_TYPE } from "./constants";

// * Layer fields
const STAGE = new LayerStageField("stage", "Stage").set_option("filter_config", { filter_method_type: FILTER_METHOD_TYPES.TEXT });
const DAYS_IN_STAGE = new LayerLatestLocationField("days_in_stage", "Days in stage").set_option("filter_config", { formatter: (value) => toFixed(value, 0), min: 0, max: 365, filter_method_type: FILTER_METHOD_TYPES.NUMBER_RANGE });
const LABEL = new LayerField("label", "Label");
const LABEL_LINK = new LabelLinkField("label", "Label");
const TYPE = new LayerField("type", "Type", {
    value_filter: (_, datasource: DataSource) => datasource.config.get_raw_layer_config(datasource.layer.type).text,
});
const PALLET = new LayerField("label", "Pallet");
const BATCH = new LayerField("label", "Batch");
const PURCHASE_ORDER = new LayerField("label", "Purchase order");
const CREATED = new LayerField("created", "Date", { formatters: [formatDate] }).set_filter_type_for_formatter("formatDate", { formatter: formatDate });

const LINK_SOURCE_PALLET = new ParentLabelLinkField("source_pallet", "Source pallet", LAYER_TYPE.PALLET);
const LINK_PURCHASE_ORDER = new ParentLabelLinkField("purchase_order", "Purchase order", LAYER_TYPE.PURCHASE_ORDER);
const LINK_CONTAINER = new ParentLabelLinkField("container", "Container", LAYER_TYPE.CONTAINER);
const LINK_CHILDREN = new ListChildrenLabelLinkField("children", "Children");
const COUNT_CHILDREN = new CountChildrenField("children", "Children");

const PARENT_PURCHASE_ORDER = new ParentLabelField("purchase_order", "Purchase order", LAYER_TYPE.PURCHASE_ORDER);

const LIST_CHILDREN_FRUIT_VARIETIES = new ListChildrenMetaField("fruit_variety", "Varieties");
const LIST_CHILDREN_GGNS = new ListChildrenMetaField("global_gap_number", "GGN");
const LIST_CHILDREN_SIZES = new ListChildrenMetaField("size", "Sizes");


// * Check fields
const CHECK_NEXT_ACTION = new CheckField("next_action", "Next action");
const CHECK_STATUS_ACTION = new CheckField("status_check", "Status action");
const CHECK_REJECTION_SATISFIED = new CheckField("rejection_satisfied", "Rejection satisfied");
const CHECK_USER_AGREEMENT = new CheckField("user_agreement", "User agreement");
const CHECK_AVERAGE_PRESSURE_MANUAL = new CheckField("user_avg_pressure", "Average pressure");
const CHECK_SPLIT_PO = new CheckField("user_split", "Split PO");
const CHECK_FLAG = new CheckFlagField("flag", "Flagged");
const CHECK_SIZE = new CheckField("size", "Size");
const CHECK_SHELF_LIFE_TYPE = new CheckField("shelf_life_type", "Shelf life type");
const CHECK_SIZE_CATEGORY = new CheckField("size_category", "Size category");
const CHECK_TEST_ID = new CheckField("test_id", "test_id");
const CHECK_LOCATION = new CheckLocationField("location", "Check type", {
    value_filter: (_, datasource: DataSource) => (datasource.check ? datasource.config.get_location(datasource.layer, datasource.check)?.text : "-"),
}).set_option("filter_config", { filter_method_type: FILTER_METHOD_TYPES.TEXT, options_name: "check_type" });
const CHECK_CREATED_DATE = new CheckField("created", "Check date", { formatters: [formatDate] }).set_filter_type_for_formatter("formatDate", { formatter: formatDate });
const CHECK_TRACEBILITY = new CheckField("tracebility", "Traceability");
const CHECK_BOX_INTEGRITY = new CheckField("box_integrity", "Box integrity");
const CHECK_NOTE = new CheckField("feedback", "Note");

const CHECK_MOVEMENT = new CheckField("movement", "Movement", {
    formatters: [(value: any) => toFixed(value, 0, "%")]
});
const CHECK_RIPENING_TARGET = new CheckField("ripening_target", "Ripening target");
const CHECK_ARRIVAL_DATE_INTAKE = new CheckField("arrival_date_intake", "Arrival date");
const CHECK_CONTAINER_CO2 = new CheckField("container_co2", "Container CO2");
const CHECK_CONTAINER_O2 = new CheckField("container_o2", "Container O2");
const CHECK_LOGGER_TEMPERATURE = new CheckField("logger_temperature", "Logger temperature");
const CHECK_INTEGRITY_SEAL = new CheckField("integrity_seal", "Seal Integrity");
const CHECK_CONTAINER_TEMPERATURE = new CheckField("container_temperature", "Container temperature");
const CHECK_VENT = new CheckField("vent", "Vent");


// * Check meta fields
const CHECK_META_OVER_SOFT = new CheckField("over_soft", "Over soft");
const CHECK_META_SOFT = new CheckField("soft", "Soft");
const CHECK_META_RTE = new CheckField("rte", "RTE");
const CHECK_META_TRIGGERED = new CheckField("triggered", "Triggered");
const CHECK_META_FRIM_WITH_MOVEMENT = new CheckField("firm_with_movement", "Firm with movement");
const CHECK_META_FIRM = new CheckField("firm", "Firm");
const CHECK_META_TEMPERATURE = new CheckField("temperature", "Pulp temp");
const CHECK_META_ACTUAL_FRUIT_IN_BOX = new CheckField("actual_fruit_in_box", "Act. fruit");
const CHECK_META_CHECK_DATE = new CheckField("check_date", "Check date");
const CHECK_META_UNAPPROVED_GROWER = new CheckField("unapproved_grower", "Unapproved grower");
const CHECK_META_HAS_CONTAMINATED_FRUIT = new CheckField("has_contaminated_fruit", "Has contaminated fruit");

// const CHECK_FRUITS_CHECKED = new CheckField("fruits_checked", "Fruits checked");

// * Aggregate fruit fields
// const AGGREGATE_FRUIT_COUNT = new AggregatedFruitField("fruits_checked", "Fruits checked"); // * Drop this in favour of CHECK_FRUITS_CHECKED in december 2024

// TODO: ask Agis to configure these fields
// const AGGREGATE_FRUIT_ATRON_PRESSURE_KG_AVG = new AggregatedFruitField("atron_pressure_kg_avg", "Avg pressure", {
//     formatters: [(value: any) => toFixed(value, 2, "kg")]
// });

// TODO DROP these predifined AGGREGATE_ fields
// const AGGREGATE_FRUIT_MATURITY_AVG = new AggregatedFruitField("maturity_avg", "Avg maturity", { formatters: [(value: any) => toFixed(value, 1)]
// });
// const AGGREGATE_FRUIT_BRIX_AVG = new AggregatedFruitField("brix_avg", "Avg brix", {
//     formatters: [(value: any) => toFixed(value, 2)]
// });

// TODO: ask Agis to configure these fields
// const AGGREGATE_FRUIT_ATRON_MEDIAN_AVG = new AggregatedFruitField("atron_pressures_median_avg", "Avg firmness", {
//     formatters: [(value: any) => toFixed(value, 0)]
// });
// const AGGREGATE_FRUIT_RIPENING_STAGE_FIRMNESS = new AggregatedFruitField("atron_pressures_median_avg", "Avg Ext firmness stage", {
//     value_filter: (value, datasource: DataSource) => datasource.config.firmness_to_ripening_stage(value).label
// });

// const AGGREGATE_FRUIT_RIPENING_CLASS_KG_PREDICTED = new LayerMetaField("pressure_avg", "Avg ripening class", {
//     value_filter: (value, datasource: DataSource) => datasource.config.kg_to_ripening_stage(value).label
// });
// const AGGREGATE_FRUIT_PRESSURE_AVG = new AggregatedFruitField("pressure_avg", "Avg penetrometer", {
//     formatters: [(value: any) => toFixed(value, 2, "kg")]
// });

// Same as above but without kg
// const AGGREGATE_FRUIT_PRESSURE_AVG_NO_UNIT = new AggregatedFruitField("pressure_avg", "Avg penetrometer", {
//     formatters: [(value: any) => toFixed(value, 2)],
//     append_key: "no_unit" // needed to make field ids unique, can be removed after we start using the variable name as key
// });

// * Aggregate defect fields
// const AGGREGATE_FRUIT_INTERNAL_DEFECTS_FRACTION = new AggregatedDefectField("internal_defected_fruit", "Internal", {
//     value_filter: (value, datasource: DataSource) => toFraction(value, datasource.check?.fruits_checked, "Clean"),
//     append_key: "toFraction"
// });

// const AGGREGATE_FRUIT_MANUAL_DRY_MATTER_AVG = new AggregatedFruitField("dry_matter_avg", "Avg dry matter", {
//     formatters: [(value: any) => toFixed(value, 0, "%")]
// });

// Double check who is using these ripening fields, and let them use internal data processing instead
const FRUIT_RIPENING_OVER_RIPE = new LayerMetaField("ripening_over_ripe", "Over ripe", {
    value_filter: (value, datasource: DataSource) => toPercentage(datasource.layer?.ripening_over_ripe, datasource.layer?.ripening_fruits)
});
const FRUIT_RIPENING_ON_TARGET = new LayerMetaField("ripening_on_target", "On target", {
    value_filter: (value, datasource: DataSource) => toPercentage(datasource.layer?.ripening_on_target, datasource.layer?.ripening_fruits)
});
const FRUIT_RIPENING_UNRIPE = new LayerMetaField("ripening_unripe", "Unripe", {
    value_filter: (value, datasource: DataSource) => toPercentage(datasource.layer?.ripening_unripe, datasource.layer?.ripening_fruits)
});


const FRUIT_INTAKE_SAMPLE_SIZE = new LayerMetaField("intake_sample_size", "Sample size");

//  datasource.layer?.intake_sample_size || datasource.layer?.intake_fruits || 0 just means we give preference to the sample size, but if that one is not available we take  actual create fruits in avos_avocado
const FRUIT_INTAKE_HARD_FRUITS = new LayerMetaField("intake_soft_fruits", "Hard", {
    value_filter: (value, datasource: DataSource) => toPercentage((datasource.layer?.intake_sample_size || datasource.layer?.intake_fruits || 0) - (datasource.layer?.intake_soft_fruits || 0), (datasource.layer?.intake_sample_size || datasource.layer?.intake_fruits || 0), 0)
});
const FRUIT_INTAKE_SOFT_FRUITS = new LayerMetaField("intake_soft_fruits", "Hard", {
    value_filter: (value, datasource: DataSource) => toPercentage((datasource.layer?.intake_soft_fruits || 0), datasource.layer?.intake_sample_size || datasource.layer?.intake_fruits || 0, 0),
    append_key: "soft" // needed to make field ids unique, can be removed after we start using the variable name as key
});


// Container data
const CONTAINER_TEMPERATURE = new LayerMetaField("container_temperature", "Container temperature", {
    formatters: [(value: any) => toFixed(value, 1, " °C")]
});
const CONTAINER_NO = new LayerMetaField("container_no", "Container no");
const FRUIT_TEMPERATURE = new LayerMetaField("fruit_temperature", "Fruit temperature", {
    formatters: [(value: any) => toFixed(value, 1, " °C")]
});
const TEMPERATURE = new LayerMetaField("logger_temperature", "Logger Temperature", {
    formatters: [(value: any) => toFixed(value, 1, " °C")]
});
const RELATIVE_ISSUE_COUNT = new LayerMetaField("relative_issue_count", "% issues", {
    formatters: [(value: any) => toFixed(value, 0, "%")]
});
const TEMPERATURE_RADIO = new LayerMetaField("logger_temperature_radio", "Logger Temperature");
const JULIAN_CODE = new LayerMetaField("julian_code", "Julian code");
const FRUIT_AGE = new LayerFruitAgeField("fruit_age", "Age", { fieldname_getter: "harvest_date" });
const BOX_TRACE_CODE = new LayerMetaField("box_trace_code", "Box trace code");
const RIPENING_TARGET = new LayerMetaField("ripening_target", "Ripening target");
const RIPENING_RESULT = new LayerMetaField("ripening_result", "Ripening result");
const START_RIPENING_DATE = new LayerMetaField("start_ripening_date", "Date for arrival in ripening");
const EXPECTED_DAY_OUT = (new LayerMetaField("expected_day_out", "Expected day out", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const RIPENING_DURATION = new LayerMetaField("ripening_duration", "Ripening duration");
const RIPENING_CYCLES = new LayerMetaField("ripening_phase", "Ripening cycles");
const RIPENING_GAS_ITERATION = new LayerMetaField("ripening_gas_iteration", "Ripening gas iteration");
const SUP_REF = new LayerMetaField("sup_ref", "Sup ref");
const SUP_PALLET_NUMBER = new LayerMetaField("supplier_pallet_number", "Supplier pallet number");
const CLIENT = new LayerMetaField("end_client", "Client");
const SIZE = new LayerMetaField("size", "Size");
const YIELD = new LayerMetaField("yield", "yield");
const SIZE_CATEGORY = new LayerMetaField("size_category", "Size category");
const ARTICLE = new LayerMetaField("article_name", "Article");
const FRUIT_TYPE = new LayerMetaField("fruit_type", "Fruit");
const META_END_PALLET = new LayerMetaField("end_pallet", "End pallet");
const CONTAINER = new LayerMetaField("container", "Container");
const BOX_SIZE = new LayerMetaField("box_size", "Box size");
const META_PURCHASE_ORDER_NUMBER = new LayerMetaField("purchase_order_number", "PO");
const GGN = new LayerMetaField("global_gap_number", "GGN (Grower)"); // Also available as managed field
const ARRIVAL_DATE_INTAKE = (new LayerMetaField("arrival_date_intake", "Arrival date", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // actual arrival date filled from intak) // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const ESTIMATED_ARRIVAL_DATE = (new LayerMetaField("estimated_date_of_arrival", "ETA", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // taken from packing lis) // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const DEPARTURE_DATE = (new LayerMetaField("departure_date", "Departure date", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const PACK_DATE = (new LayerMetaField("pack_date", "Pack date", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const DEPOT_DATE = (new LayerMetaField("depot_date", "Depot date", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const ARRIVAL_NOTE = new LayerMetaField("arrival_feedback", "Notes");
const CUSTOMER_REFERENCE_NUMBER = new LayerMetaField("customer_reference_number", "Customer reference number");
const REFERENCE_NUMBER_QC4U = new LayerMetaField("reference_number_QC4U", "Reference number QC4U");
const EXPORTER = new LayerMetaField("exporter", "Exporter");
const META_FIELD = new LayerMetaField("field_input", "Field");
const META_STAGE = new LayerMetaField("stage_input", "Stage");
const HARVEST_DATE = (new LayerMetaField("harvest_date", "Harvest date", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const INSPECTION_DATE = new LayerMetaField("inspection_date", "Inspection date", { formatters: [formatDate] });
const GROWER = new LayerMetaField("grower", "Grower");
const SOURCE_PO = new LayerMetaField("source_po", "Source PO");
const PRODUCT_DESCRIPTION = new LayerMetaField("product_description", "Product description");
const CUSTOMER = new LayerMetaField("customer", "Customer");
const TEMPERATURE_TYPE = new LayerMetaField("temperature_type", "Temperature type");
const DU_DATE = new LayerMetaField("du_date", "DU date");
const PRODUCT = new LayerMetaField("product", "Product");
const SALES_ORDER = new LayerMetaField("sales_order", "Sales Order");
const BOX_CONDITION = new LayerMetaField("box_condition", "Box condition"); // !warning was managed, now all meta is managed
const CONTAINER_CLASS = new LayerMetaField("container_class", "Class"); // !warning was managed, now all meta is managed
const COUNTRY = new LayerMetaField("country", "Country"); // !warning was managed, now all meta is managed
const INTEGRITY_SEAL = new LayerMetaField("integrity_seal", "Seal Integrity"); // !warning was managed, now all meta is managed
const PALLET_CONDITION = new LayerMetaField("pallet_condition", "Pallet condition"); // !warning was managed, now all meta is managed
const RIPENING_CELL = new LayerMetaField("ripening_cell", "Ripening cell"); // !warning was managed, now all meta is managed
const SUPPLIER = new LayerMetaField("supplier", "Supplier"); // !warning was managed, now all meta is managed
const VENT_OPEN_CLOSE = new LayerMetaField("vent_open_close", "Vent"); // !warning was managed, now all meta is managed
const FRUIT_VARIETY = new LayerMetaField("fruit_variety", "Variety"); // !warning was managed, now all meta is managed
const QUANTITY = new LayerMetaField("quantity", "Quantity");
const DATE_CODE = new LayerMetaField("date_code", "Date code");
const BATCH_CODE = new LayerMetaField("batch_code", "Batch code");
const SOURCE_PALLET_ID = new LayerMetaField("source_pallet_id", "Source pallet id");
const PALLET_INTEGRITY = new LayerMetaField("pallet_integrity", "Pallet integrity");
const REASON_FOR_CUTTING = new LayerMetaField("reason_for_cutting", "Reason for cutting");
const REASON_FOR_REJECTION = new LayerMetaField("reason_for_rejection", "Reason for rejection");
const REJECTION_DATE = (new LayerMetaField("rejection_date", "Date of rejection", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const RETURN_DATE = (new LayerMetaField("return_date", "Date of return", {
    value_filter: (value) => formatDate(value)
})).set_filter_type_for_formatter("formatDate", { formatter: formatDate }); // TODO: use CUSTOM_LAYER_META with dateFormatter instead
const INTAKE_MOVEMENT = new LayerMetaField("intake_movement", "Movement");
const INTAKE_STATUS_CHECK = new LayerMetaField("intake_status_check", "status_check");
const NUMBER_OF_BOXES = new LayerMetaField("number_of_boxes", "Number of boxes");
const VESSEL = new LayerMetaField("vessel", "Vessel");
const UNACCEPTABLE_DEVIATIONS = new LayerMetaField("unacceptable_deviations", "Unacceptable deviations");
const COLLI = new LayerMetaField("colli", "Colli");
const CUSTOM_LAYER_META = new LayerMetaField("layer_meta_key", "Your label for layer meta");


const CUSTOM_CHECK_META = new CheckField("check_meta_key", "Your label for check meta");

const INTAKE_BOX_NET_WEIGHT = new LayerMetaField("intake_box_net_weight", "Box net weight");
const INTAKE_REPORT_STATUS = new LayerMetaField("intake_report_status", "Report");
const QRC_REPORT_STATUS = new LayerMetaField("reassessment_report_status", "Report");
const INSPECTED_BY = new LayerMetaField("inspected_by", "Inspected by");
const RECEIVING_WAREHOUSE = new LayerMetaField("receiving_warehouse", "Receiving warehouse");
const VOYAGE_NUMBER = new LayerMetaField("voyage_number", "Voyage number");
const BOX_WEIGHT = new LayerMetaField("box_weight", "Box weight");
const BOX_COUNT = new LayerMetaField("box_count", "Box count");
// const LAB_CHECK_DM_BRIX_AVG = new LayerMetaField("lab_check_brix_dm_avg", "DM");
const GROWER_NAME = new LayerMetaField("grower_name", "Grower");
const CHECK_DATE = new LayerMetaField("check_date", "Check date");
const FRUIT_ARRIVAL_DELAY = new LayerFruitArrivalDelayField("arrival_delay", "Arrival delay");

const All_STAGES_STATUS = new LayerStagesColorBadgeField("all_stages_status", "Status");

// WARNING: prefer to use LayerMetaColorBadgeField instead of CheckColorBadgeField becuase it will not change bases on last check
const FRUIT_INTERNAL_DEFECTS_BADGE = new CheckColorBadgeField("flag_internal_defects", "Int. defects");
const FRUIT_BRIX_AVG_BADGE = new CheckColorBadgeField("flag_average_brix", "Avg brix");
const FRUIT_MATURITY_AVG_BADGE = new CheckColorBadgeField("flag_average_maturity", "Avg maturity");
const FRUIT_MIX_MATURITY_BADGE = new CheckColorBadgeField("flag_maturity_dist", "Mixed maturity");

const FRUIT_INTAKE_DEFECTS_BADGE = new LayerMetaColorBadgeField("intake_flag_defects", "Defects");
const FRUIT_INTAKE_RIPENESS_BADGE = new LayerMetaColorBadgeField("intake_flag_ripeness", "Ripeness");
const FRUIT_INTAKE_HOMOGENEITY_BADGE = new LayerMetaColorBadgeField("intake_flag_homogeneity", "Homogeneity");
const FRUIT_INTAKE_DM_BRIX_BADGE = new LayerMetaColorBadgeField("intake_flag_dm_brix", "DM");
const FRUIT_STORAGE_RIPENING_SPEED = new LayerMetaColorBadgeField("storage_flag_ripening_speed", "Ripening speed");


// LayerMetaColorBadge
const RIPENING_STATUS = new LayerMetaColorBadgeField("ripening_status", "Status");
const STORAGE_STATUS = new LayerMetaColorBadgeField("storage_status", "Status");
const FINAL_CHECK_STATUS = new LayerMetaColorBadgeField("final_check_status", "Status");
const INTAKE_STATUS = new LayerMetaColorBadgeField("intake_status", "Status");
const PRODUCTION_STATUS = new LayerMetaColorBadgeField("production_status", "Status");
const SHELF_LIFE_STATUS = new LayerMetaColorBadgeField("shelf_life_status", "Status");
const CHECK_PASS_STATUS = new LayerMetaColorBadgeField("check_pass_status", "Status");
const ADVANCE_RIPENING_STATUS = new LayerMetaColorBadgeField("advance_ripening_status", "Pallet score");

const CUSTOM_RIPENING_CELL_META = new RipeningCellMetaFields("ripening_cell_meta_key", "Your label for cell meta");

const QC_STATUS_ADVANCE_RIPENING = new QCStatusField("qc_status_advance_ripening", "Advance ripening");
const QC_STATUS_INTAKE = new QCStatusField("qc_status_intake", "Intake");


// show all badges in one column
const FRUIT_INTAKE_STATUS_BADGES = new LayerStageColorBadgesField(
    "intake_status",
    "Status",
    [
        "intake_flag_defects",
        "intake_flag_ripeness",
        "intake_flag_dm_brix",
        "intake_flag_homogeneity",
    ]
);


// show all badges in one column
const FRUIT_RIPENING_STATUS_BADGES = new LayerStageColorBadgesField(
    "ripening_status",
    "Ripening status",
    [
        "ripening_flag_ripeness",
    ]
);

// show all badges in one column
const FRUIT_STORAGE_STATUS_BADGES = new LayerStageColorBadgesField(
    "storage_status",
    "Storage status",
    [
        "storage_flag_ripening_speed",
    ]
);


export const LAYER = {
    CUSTOM_LAYER_META,
    // CUSTOM_META_PERCENTAGE,
    CUSTOM_CHECK_META,
    // CUSTOM_CHECK_META_PERCENTAGE,
    // CUSTOM_META_FLOAT,
    STAGE,
    DAYS_IN_STAGE,
    LABEL,
    LABEL_LINK,
    TYPE,
    PALLET,
    BATCH,
    PURCHASE_ORDER,
    CREATED,
    LINK_SOURCE_PALLET,
    LINK_PURCHASE_ORDER,
    LINK_CONTAINER,
    LINK_CHILDREN,
    COUNT_CHILDREN,
    PARENT_PURCHASE_ORDER,
    LIST_CHILDREN_FRUIT_VARIETIES,
    LIST_CHILDREN_GGNS,
    LIST_CHILDREN_SIZES,
    BOX_CONDITION,
    CONTAINER_CLASS,
    COUNTRY,
    GGN,
    INTEGRITY_SEAL,
    PALLET_CONDITION,
    RIPENING_CELL,
    SUPPLIER,
    VENT_OPEN_CLOSE,
    FRUIT_VARIETY,
    CHECK_NEXT_ACTION,
    CHECK_STATUS_ACTION,
    CHECK_USER_AGREEMENT,
    CHECK_AVERAGE_PRESSURE_MANUAL,
    CHECK_SPLIT_PO,
    CHECK_FLAG,
    CHECK_SIZE,
    CHECK_SIZE_CATEGORY,
    CHECK_TEST_ID,
    CHECK_LOCATION,
    CHECK_CREATED_DATE,
    CHECK_TRACEBILITY,
    CHECK_BOX_INTEGRITY,
    CHECK_SHELF_LIFE_TYPE,
    CHECK_MOVEMENT,
    CHECK_REJECTION_SATISFIED,
    CHECK_PASS_STATUS,
    CHECK_NOTE,
    CHECK_RIPENING_TARGET,
    CHECK_ARRIVAL_DATE_INTAKE,
    CHECK_VENT,
    CHECK_CONTAINER_CO2,
    CHECK_CONTAINER_O2,
    CHECK_LOGGER_TEMPERATURE,
    CHECK_INTEGRITY_SEAL,
    CHECK_CONTAINER_TEMPERATURE,

    CONTAINER_TEMPERATURE,
    CONTAINER_NO,
    FRUIT_TEMPERATURE,
    TEMPERATURE,
    RELATIVE_ISSUE_COUNT,
    TEMPERATURE_RADIO,
    JULIAN_CODE,
    BOX_TRACE_CODE,
    RIPENING_TARGET,
    RIPENING_RESULT,
    START_RIPENING_DATE,
    EXPECTED_DAY_OUT,
    RIPENING_DURATION,
    RIPENING_CYCLES,
    RIPENING_GAS_ITERATION,
    SUP_REF,
    SUP_PALLET_NUMBER,
    CLIENT,
    SIZE,
    YIELD,
    SIZE_CATEGORY,
    ARTICLE,
    FRUIT_TYPE,
    META_END_PALLET,
    CONTAINER,
    BOX_SIZE,
    META_PURCHASE_ORDER_NUMBER,
    PACK_DATE,
    DEPOT_DATE,
    ARRIVAL_NOTE,
    CUSTOMER_REFERENCE_NUMBER,
    REFERENCE_NUMBER_QC4U,
    EXPORTER,
    META_FIELD,
    META_STAGE,
    HARVEST_DATE,
    INSPECTION_DATE,
    GROWER,
    SOURCE_PO,
    PRODUCT_DESCRIPTION,
    CUSTOMER,
    TEMPERATURE_TYPE,
    DU_DATE,
    PRODUCT,
    SALES_ORDER,
    QUANTITY,
    DATE_CODE,
    BATCH_CODE,
    SOURCE_PALLET_ID,
    PALLET_INTEGRITY,
    REASON_FOR_CUTTING,
    All_STAGES_STATUS,
    FRUIT_INTERNAL_DEFECTS_BADGE,
    FRUIT_BRIX_AVG_BADGE,
    FRUIT_MATURITY_AVG_BADGE,
    FRUIT_MIX_MATURITY_BADGE,
    RIPENING_STATUS,
    STORAGE_STATUS,
    FINAL_CHECK_STATUS,
    INTAKE_STATUS,
    PRODUCTION_STATUS,
    SHELF_LIFE_STATUS,
    FRUIT_RIPENING_OVER_RIPE,
    FRUIT_RIPENING_ON_TARGET,
    FRUIT_RIPENING_UNRIPE,

    FRUIT_INTAKE_DEFECTS_BADGE,
    FRUIT_INTAKE_RIPENESS_BADGE,
    FRUIT_INTAKE_HOMOGENEITY_BADGE,
    FRUIT_INTAKE_DM_BRIX_BADGE,
    FRUIT_INTAKE_HARD_FRUITS,
    FRUIT_INTAKE_SOFT_FRUITS,
    FRUIT_INTAKE_SAMPLE_SIZE,
    FRUIT_STORAGE_RIPENING_SPEED,
    FRUIT_INTAKE_STATUS_BADGES,
    // FRUIT_INTAKE_UNDERWEIGHT_FRUITS,
    FRUIT_RIPENING_STATUS_BADGES,
    FRUIT_STORAGE_STATUS_BADGES,
    // FRUIT_ART_EXTERNAL_DEFECTED,
    // FRUIT_INTAKE_EXTERNAL_DEFECTS_VISUAL_CHECK,
    // FRUIT_INTAKE_UNACCEPTABLE_EXTERNAL_DEFECTS_VISUAL_CHECK,
    // FRUIT_INTAKE_MINOR_EXTERNAL_DEFECTS_VISUAL_CHECK,
    // FRUIT_INTAKE_MAJOR_EXTERNAL_DEFECTS_VISUAL_CHECK,
    FRUIT_AGE,
    INTAKE_MOVEMENT,
    INTAKE_STATUS_CHECK,
    REASON_FOR_REJECTION,
    REJECTION_DATE,
    RETURN_DATE,
    NUMBER_OF_BOXES,
    VESSEL,
    UNACCEPTABLE_DEVIATIONS,
    COLLI,
    INTAKE_BOX_NET_WEIGHT,
    INTAKE_REPORT_STATUS,
    QRC_REPORT_STATUS,
    INSPECTED_BY,
    RECEIVING_WAREHOUSE,
    CHECK_META_OVER_SOFT,
    CHECK_META_SOFT,
    CHECK_META_RTE,
    CHECK_META_TRIGGERED,
    CHECK_META_FRIM_WITH_MOVEMENT,
    CHECK_META_FIRM,
    CHECK_META_TEMPERATURE,
    CHECK_META_ACTUAL_FRUIT_IN_BOX,
    CHECK_META_CHECK_DATE,
    CHECK_META_UNAPPROVED_GROWER,
    CHECK_META_HAS_CONTAMINATED_FRUIT,
    VOYAGE_NUMBER,
    BOX_WEIGHT,
    BOX_COUNT,
    ESTIMATED_ARRIVAL_DATE,
    ARRIVAL_DATE_INTAKE,
    DEPARTURE_DATE,
    // LAB_CHECK_DM_BRIX_AVG,
    ADVANCE_RIPENING_STATUS,
    GROWER_NAME,
    CHECK_DATE,
    FRUIT_ARRIVAL_DELAY,
    CUSTOM_RIPENING_CELL_META,
    QC_STATUS_ADVANCE_RIPENING,
    QC_STATUS_INTAKE,
};
