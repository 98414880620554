import { useSelector } from "react-redux";
import { getFilledArrayOrDefault } from "./getFilledArrayOrDefault";

export function userHasRole(user: any, role: string) {
    return getFilledArrayOrDefault(user.roles).includes(role);
}

export function userHasOneOfRoles(user: any, roles: string[]) {
    return getFilledArrayOrDefault(roles).some((role) => userHasRole(user, role));
}

export function userBelongsToTeam(user: any, team: string) {
    return getFilledArrayOrDefault(user.teams).includes(team);
}

export function userBelongsToOneOfTeams(user: any, teams: string[]) {
    return getFilledArrayOrDefault(teams).some((team) => userBelongsToTeam(user, team));
}

export default function useAuthorization() {
    const user = useSelector((state:any) => state?.auth?.user);
    return {
        userHasRole: (role: string) => userHasRole(user, role),
        userHasOneOfRoles: (roles: string[]) => userHasOneOfRoles(user, roles),
        userBelongsToTeam: (team: string) => userBelongsToTeam(user, team),
        userBelongsToOneOfTeams: (teams: string[]) => userBelongsToOneOfTeams(user, teams),
    };

}
