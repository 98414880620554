// For type
import { faFlagSwallowtail } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionFlagged, RadioOption } from "../Tenants/config/constantsTyped";
import { AVOCADO_FRUIT } from "../Tenants/config/fruitNames";


export interface LayerFilter {
    location?: LAYER_STAGE | string | undefined;
    stage?: LAYER_STAGE | LAYER_STAGE[] | string | undefined;
    assigned_team?: string | undefined;
    location_id?: string | undefined;
    layer_type?: LAYER_TYPE | string | undefined;
    view_strategy?: VIEW;
    select_fields?: string[];
    search?: string;
    ordering?: string;
    limit?: number
    offset?: number
    meta_filter?: object | string;
    fruit_type?: string[] | undefined;
}
interface LayerLocation {
    id: string;
    layer_id: string;
    action_id?: string | null;
    stage?: string | null;
    location?: string | null;
    user_id: number;
    active?: boolean | null;
    created: Date;
    location_id?: string | null;
}

export interface Related {
    type: string;
    id: string;
    label: string;
}

export interface Parent {
    type: string;
    id: string;
    label: string;
}

// export interface AggregatedFruit {
//     count?: number;
//     atron_pressure_kg_max?: number;
//     atron_pressure_kg_min?: number;
//     atron_pressure_kg_avg?: number;
//     atron_pressures_median_max?: number;
//     atron_pressures_median_min?: number;
//     atron_pressures_median_avg?: number;
//     dry_matter_avg?: number;
//     maturity_avg?: number;
//     brix_avg?: number;
//     pressure_avg?: number;
// }

export interface Image {
    id: number;
    url?: string;
    url_webp?: string;
    type: string;
    blob_name?: string;
    thumbnail?: string;
    test_id?: number;
    layer_id?: string | undefined;
}

export interface Defect {
    defect_id: string;
    frequency: number;
}

export interface Fruit {
    avocado_id: number;
    device_uid?: string;
    model_name?: string;
    images?: Image[]; // Using the previously defined Image interface
    atron_color_stage?: number;
    atron_quality?: string;
    // atron_pressure: None; // This line seems commented out
    atron_pressure_kg?: number;
    atron_pressure_kg_1?: number;
    atron_pressure_kg_2?: number;
    atron_pressures: (number | null)[];
    atron_pressure_range?: number;
    atron_pressures_median?: number;
    atron_pressures_average?: number;
    atron_weight_gram?: number;
    test_id: number;
    user_action?: string;
    manual_color_stage?: number;
    manual_internal_color_stage?: number;
    weight?: number;
    manual_quality?: string;
    defects: Defect[]; // Using the previously defined Defect interface
    pressure?: number;
    pressure_1?: number;
    pressure_2?: number;
    is_invalid: boolean;
    manual_dry_matter?: number;
    manual_brix?: number;
    manual_maturity?: number;
    manual_temperature?: number;
    is_shiny?: string;
    manual_taste?: string;
    durometer_pressure_1?: number;
    durometer_pressure_2?: number;
}

export interface Check {
    test_id: number;
    avocados: Fruit[]; // Using the previously defined Fruit interface
    images: Image[]; // Using the previously defined Image interface

    fruits_checked: number | null
    // atron_pressure_kg_avg?: number
    // atron_pressures_median_max?: number
    // atron_pressures_median_min?: number
    // atron_pressures_median_avg?: number
    dry_matter_avg?: number
    maturity_avg?: number
    brix_avg?: number
    pressure_avg?: number

    location_id?: string;
    layer_id: string | [string] // UUID is typically represented as a string in TypeScript
    created: Date; // TypeScript uses the built-in Date type
    is_pre_ripening?: string;
    like?: number;
    defect_avocados: number;
    // user?: User; // Assuming you have a User interface defined elsewhere
    // is_maxi_check?: string;
    // maxi_exit?: string;
    // maxi_process_name?: string;
    location: string;
    feedback?: string;
    pallet_status?: string;
    next_action?: string;
    status_check?: string;
    user_agreement?: string;
    user_avg_pressure?: number;
    user_split?: string;
    updated: Date;
    desired_stage?: string;
    is_manual?: boolean;
    box_size?: number;
    size_category?: string;
    size?: number;
    tenant_id: string;
    flag?: string;
    flag_internal_defects?: string;
    flag_average_brix?: string;
    flag_maturity_dist?: string;
    flag_average_maturity?: string;
    flag_julian_code?: string;
    has_pressure_calibration?: boolean;
    tracebility?: string;
    weight_kg?: number;
    pallet_integrety?: string;
    box_integrity?: string;
    internal_defected_fruit?: number;
    external_defected_fruit?: number;
    total_defected_fruit?: number;
    soft?: number;
    rte?: number;
    over_soft?: number;
    temperature_fruit?: number;
    internal_defect_status?: string | boolean;
    sample_size?: number | null;
    requested_calibration_stage?: string; // can reuse calibrations from this stage
}


// export interface Filter {
//     layer: string;
//     location: string;
//     search: string;
//     limit: number;
//     ordering: string;
// }

export const defaultFilter: LayerFilter = {
    location: "",
    search: "",
    limit: 25,
    ordering: "check.created,DESC",
    meta_filter: {},
};

export interface CalibratedStage {
    calibration_stage: LAYER_STAGE;
    count: number;
}
export interface Layer {
    id: null | string; // Adjust the type as needed
    label: string;
    fruit_type?: null | string;
    fruit_variety?: string
    tenant_id?: string;
    user_id?: number;
    type?: LAYER_TYPE
    children: Related[];
    parents: Related[];
    parent_meta?: object | Array<object>; // In backend form it is an array of objects, but in frontend it is an object
    latest_location?: LayerLocation | null
    created?: Date;
    updated?: Date | null;
    latest_check?: Check | null;
    calibrated_stages?: CalibratedStage[];
    ripening_over_ripe?: number | null
    ripening_on_target?: number | null
    ripening_unripe?: number | null
    ripening_fruits?: number | null
    intake_internal_defected_fruit?: number | null
    intake_external_defected_fruit?: number | null
    intake_fruits?: number | null
    intake_sample_size?: number | null
    intake_visual_check_total_external_defects?: number | null
    intake_soft_fruits?: number | null
    advance_ripening_total_external_defects?: number | null
    arrival_date_intake?: Date;
    estimated_date_of_arrival?: Date;
    lab_check_average_dry_matter?: number | null
    intake_underweight_fruits?: number | null
    intake_total_internal_defects?: number | null
    intake_visual_check_external_defects_percentage?: number | null
    departure_date?: Date;
}


export enum VIEW {
    SELF = "self",
    ALL = "all",
    CHILDREN = "children",
    AGGREGATE = "aggregate",
    EXTRAPOLATE = "extrapolate"
}

export enum LAYER_TYPE {
    PALLET = "pallet",
    PURCHASE_ORDER = "purchase_order",
    SALES_ORDER = "sales_order",
    RIPENING_ORDER = "ripening_order",
    BATCH = "batch",
    LOT = "lot",
    CONTAINER = "container",
    TRUCK = "truck",
    END_PALLET = "end_pallet",
    SUBPALLET = "subpallet",
}

export enum LAYER_STAGE {
    INTAKE = "intake",
    STORAGE = "storage",
    RIPENING = "ripening",
    PRODUCTION = "production",
    FINALCHECK = "final_check",
    SHELFLIFE = "shelf_life",
    DELIVERY = "delivery",
    RETURNS = "returns",
}
// * Every stage can be a tab, but not every tab is a stage
export enum LAYER_TABS {
    // * Stock category
    STOCK = "stock",
    ARCHIVE = "archive",

    // * Transit category
    IN_TRANSIT = "in_transit",
    PACKING_LIST = "packing_list",

    // * Operation category or legacy EYE
    // * These are stages/tabs where they do random/informal/operational checks
    STORAGE = "storage",
    SHELFLIFE = "shelf_life",

    // * QC Planning category
    PLANNING = "planning",
    INTAKE = "intake",
    RIPENING = "ripening",
    PRODUCTION = "production",

    // * QC Report category
    QC_DRAFT_REPORT = "qc_draft_report", // * Layer with draft reports
    QC_REPORT = "qc_report", // * Layer with send reports
    QC_MAILBOX = "qc_mailbox", // * Email status

    // * mischellaneous, will default to operation category
    DATA_CAPTURING = "data_capturing",
    FINALCHECK = "final_check",
    DELIVERY = "delivery",
    RETURNS = "returns",
    READY = "ready",
}

export enum LAYER_TAB_CATEGORIES {
    STOCK = "stock", //  Show the current stock, and an archive of all previous stock
    QC_PLANNING = "qc_planning", // Quality control status
    QC_REPORT = "qc_report", // Quality control report
    OPERATION = "operation", // Ad hoc, informal or random checks Storage, ripening
    TRANSIT = "transit", // Transit and packing list
}

export const CATEGORIES_WITH_CHECK_BUTTON = [LAYER_TAB_CATEGORIES.QC_PLANNING, LAYER_TAB_CATEGORIES.OPERATION];

export enum GROUP_STRATEGY {
    PURCHASE_ORDER = "purchase_order",
    BOX_SIZE = "box_size",
    GGN = "global_gap_number",
    FRUIT_VARIETY = "fruit_variety",
    PALLET = "pallet",
}

export const GROUP_STRATEGY_LABELS = {
    [GROUP_STRATEGY.PURCHASE_ORDER]: "Purchase Order",
    [GROUP_STRATEGY.PALLET]: "Pallet",
    [GROUP_STRATEGY.GGN]: "GGN",
    [GROUP_STRATEGY.BOX_SIZE]: "Box size",
};

export enum CHECK_LOCATION {
    INTAKE = "intake",
    STORAGE = "storage",
    RIPENING = "ripening",
    PRODUCTION = "production",
    FINALCHECK = "final_check",
    SHELFLIFE = "shelf_life",
    DELIVERY = "delivery",
    RETURNS = "returns",
    LAB_CHECK = "lab_check",
    ADVANCE_RIPENING = "advance-ripening", // TODO: write migration to underscore
    DATA_CAPTURING = "data_capturing",
    ADVANCE_RIPENING_SHELF_LIFE = "advance_ripening_shelf_life",
    ARRIVAL = "arrival",
    BOX_WEIGHT = "box_weight_check",

}

export const ART_LOCATIONS: string[] = [
    CHECK_LOCATION.ADVANCE_RIPENING,
    CHECK_LOCATION.ADVANCE_RIPENING_SHELF_LIFE
];

export const isArtLocation = (location: string): boolean => ART_LOCATIONS.includes(location);

export const defaultLayer: Layer = {
    id: "",
    label: "",
    // type: "",
    fruit_type: AVOCADO_FRUIT,
    children: [],
    parents: [],
};

export const optionsFlagged: OptionFlagged[] = [
    { value: "flag-success", text: <FontAwesomeIcon icon={faFlagSwallowtail} />, color: "success", className: "fs-5 py-3 px-4 me-2", disabled: false },
    { value: "flag-warning", text: <FontAwesomeIcon icon={faFlagSwallowtail} />, color: "warning", className: "fs-5 py-3 px-4 me-2", disabled: false },
    { value: "flag-danger", text: <FontAwesomeIcon icon={faFlagSwallowtail} />, color: "danger", className: "fs-5 py-3 px-4 me-2", disabled: false },
];

export const AVOS_GRAPH_COLORS = {
    AVOS_SUCCESS: "#009432",
    AVOS_WARNING: "#F79F1F",
    AVOS_DANGER: "#EA2027",
    AVOS_BLUE: "#a6a6a6",
} as const;

export const optionsYesNo: RadioOption[] = [
    { value: "yes", text: "Yes" },
    { value: "no", text: "No" },
];

export const optionsGoodPoor: RadioOption[] = [
    { value: "good", text: "Good" },
    { value: "poor", text: "Poor" },
];

export const options1or2: RadioOption[] = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
];

export const optionsGoodRestack: RadioOption[] = [
    { value: "good", text: "Good" },
    { value: "restack_required", text: "Restack required" },
];


export enum CHECK_CARD_TYPE {
    DATE = "date",
    LABEL = "label",
    SIZE_CATEGORY = "size_category",
    SIZE_CATEGORY_OR_LABEL = "size_category_or_label",
}

export interface MetricCard {
    // type: string;
    // id: string;
    key: string,
    label: string;
}
export interface RipeningCell {
    key: string;
    value: string | number | null;
}

export const avocado_min_weight = [
    { box_size: 4, weight: 781 },
    { box_size: 6, weight: 576 },
    { box_size: 8, weight: 456 },
    { box_size: 10, weight: 364 },
    { box_size: 12, weight: 300 },
    { box_size: 14, weight: 258 },
    { box_size: 16, weight: 227 },
    { box_size: 18, weight: 203 },
    { box_size: 20, weight: 184 },
    { box_size: 22, weight: 165 },
    { box_size: 24, weight: 151 },
    { box_size: 26, weight: 144 },
    { box_size: 28, weight: 134 },
    { box_size: 30, weight: 123 },
    { box_size: 32, weight: 110 }
];

export enum UNIT_OF_ACCOUNT {
    FRUIT = "fruit",
    BOX = "box",
}
